import React from 'react';
import './MenuOptions.module.css';
import './../../css/process-stages.css';
import './../../css/bx.integration.css';
const menuOptionList = [
  { id: 1, title: "BX-Geolocation", action: "bx-geo", final: false },
  { id: 2, title: "BX-Pricing", action: "bx-pricing", final: false },
  { id: 3, title: "BX-Emission", action: "bx-emission", final: false },
  { id: 4, title: "BX-Label", action: "bx-label", final: false },
  { id: 5, title: "BX-Tracking Pull", action: "bx-tracking-pull", final: false }

]
const Conector = (show) => {
  if (!show.show)
    return (
      <div className="process-connector">
        <div className="connector"></div>
      </div>
    )
  else
    return (<div></div>)
}
const Item = (menu) => {
  return (<div>
    <div className="process-step">
      <div className="process-stage process-circle process-stage" data-action={menu.menu.action}>
        <div className="inside-circle" data-action="menu.menu.action"></div>
      </div>
      <div className="process-title process-title-action" data-action={menu.menu.action}>{menu.menu.title}</div>
    </div>
    <Conector show={menu.menu.final} />
  </div>
  )
};

const MenuOptions = () => (
  <div className="col-12 col-sm-4 col-md-3 col-lg-3 d-flex justify-content-center" data-testid="MenuOptions">
    <div className="process-flow-container">
      {menuOptionList.map(menuOption => (
        <Item menu={menuOption} />
      ))}

    </div>
  </div>
);

export default MenuOptions;
