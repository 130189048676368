import React from 'react';
import './../../css/bx.integration.css';
import './../../css/snippet.css';
import BxGeo from './../Bx-geo/Bx-geo';
import BxEmission from './../BxEmission/BxEmission';
import BxPricing from '../BxPricing/BxPricing';
import BxLabel from '../BxLabel/BxLabel';
import BxTrackingPull from '../BxTrackingPull/BxTrackingPull';
import BxTrackingPush from '../BxTrackingPush/BxTrackingPush';

const Sider = () => (
    <div className="process-detail-container" data-testid="Sider">
        <div className="container-fluid">
            <div className="row">
                <div className="col-8 process-detail">
                    <div className="process-detail-bx-geo process-title">BX-Geolocation</div>
                    <div className="process-detail-bx-pricing process-title">BX-Pricing</div>
                    <div className="process-detail-bx-emission process-title">BX-Emission</div>
                    <div className="process-detail-bx-label process-title">BX-Label</div>
                    <div className="process-detail-bx-tracking-pull process-title">BX-Tracking Pull</div>
                   
                </div>
                <div className="col-4 d-flex justify-content-end align-items-center">
                    <i className="far fa-times-circle fa-2x btn btn-close-details"></i>
                </div>
            </div>
            <div className="row">
                <div className="col-12 process-detail-container-body">
                    <BxGeo />
                    <BxPricing />
                    <BxEmission />
                    <BxLabel />
                    <BxTrackingPull />
                    <BxTrackingPush />
                    </div>
            </div>
        </div>
    </div>

);

Sider.propTypes = {};

Sider.defaultProps = {};

export default Sider;
