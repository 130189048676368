import React from 'react';
import PropTypes from 'prop-types';
import styles from './BxTrackingPull.module.css';
import trackingPullResponse from './../../jsons/trackingPullResponse';
import BxHeaders from './../BxHeaders2/BxHeaders';
import exceptionSchema from '../../jsons/exceptionSchema';
import exceptionSample from '../../jsons/exceptionSample';

const BxTrackingPull = () => (
  <div className="process-detail-bx-tracking-pull">
    <div className="process-detail-description">API Bluex Tracking Pull allows you to get the tracking data of a specific shipment.</div>
    <BxHeaders />
    <div className="documentation-container">
      <div className="process-detail-subtitle">BX-Tracking Pull</div>
      <div className="snippet">
        <div className="process-detail-description">Allows you to get the tracking data.</div>
        <div className="endpoint-url">GET -https://bx-tracking.bluex.cl/bx-tracking/v1/tracking-pull/<em><strong>trackingNumber</strong></em></div>
        <div className="process-detail-description"><em><strong>trackingNumber</strong></em> is the OS number to get from Bluex platform.</div>
        <div className="process-detail-subtitle">Schema Response</div>
        <div className="process-detail-description">Here you can find a sample response.</div>
        <div><pre className="json">{JSON.stringify(trackingPullResponse, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Schema Exception</div>
        <div className="process-detail-description">Here you can find the exception schema especification.</div>
        <div><pre className="json">{JSON.stringify(exceptionSchema, null, 2)}</pre></div>
        <div className="process-detail-subtitle">Samples</div>
        <div className="process-detail-description">Here is a exception sample.</div>
        <div><pre className="json">{JSON.stringify(exceptionSample, null, 2)}</pre></div>
      </div>
    </div>
  </div>

);

BxTrackingPull.propTypes = {};

BxTrackingPull.defaultProps = {};

export default BxTrackingPull;
